const itfFairParticipantionCircularInfo = '/itf-trade-fair/fair-parti-circular-info/'
export const itfFairPartiCircularList = itfFairParticipantionCircularInfo + 'list'
export const getFairStallSubsidyInfo = itfFairParticipantionCircularInfo + 'fair-stall-subsidy-info'
export const getSubsidyInfo = itfFairParticipantionCircularInfo + 'subsidy-info'
export const fairPartiCircularStore = itfFairParticipantionCircularInfo + 'store'
export const fairPartiCircularUpdate = itfFairParticipantionCircularInfo + 'update'
export const userListByDesignationApi = 'user/users-by-designation-id'
export const itfFairPartiForward = itfFairParticipantionCircularInfo + 'forward'
export const fairPartiCircularDeadlineExtendStore = itfFairParticipantionCircularInfo + 'extended-deadline'
export const preDeadlineHistory = itfFairParticipantionCircularInfo + 'pre-deadline-histoty'
export const itfFairPartiCircularForwardList = itfFairParticipantionCircularInfo + 'forward-list'
export const itfFairPartiCircularApproveReturn = itfFairParticipantionCircularInfo + 'approve-return'
export const itfFairPartiCircularForwardCommentsApi = itfFairParticipantionCircularInfo + 'forward-comments-list'
export const itfFairPartiCircularApprovalList = itfFairParticipantionCircularInfo + 'approval-list'
export const fairPartiCircularPublish = itfFairParticipantionCircularInfo + 'publish'
export const fairPartiCircularReturn = itfFairParticipantionCircularInfo + 'return'
export const fairPartiCircularDetails = itfFairParticipantionCircularInfo + 'details'
export const stallCategoryList = 'itf-configuration/stall-category'

// Fair Parti Circular Mail Apis
export const itfFairPartiCircularMailList = itfFairParticipantionCircularInfo + 'mail-list'
export const itfFairPartiCircularMailSend = itfFairParticipantionCircularInfo + 'sending-mail'
